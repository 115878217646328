import create from "zustand";

const useStore = create((set) => ({
  meshGroups: {},
  selectedVariant: "",
  visibleGroup: "",
  groundHeight: 0,
  coffeemachinecolor: "#000000",
  coffeemachinehandleColor: "#FBFCFC",
  selectedcoffeemachine: "default",
  crestlockbagColor: "#D2042D",
  elenwedgeSandalcolor: "#be6036",
  setElenwedgeSandalcolor: (value) => set({ elenwedgeSandalcolor: value }),
  setCrestlockbagColor: (value) => set({ crestlockbagColor: value }),
  setMeshGroups: (groups) => set({ meshGroups: groups }),
  setSelectedVariant: (variant) => set({ selectedVariant: variant }),
  setVisibleGroup: (group) => set({ visibleGroup: group }),
  setGroundHeight: (value) => set({ groundHeight: value }),
  setCoffeemachinecolor: (value) => set({ coffeemachinecolor: value }),
  setCoffeemachinehandleColor: (value) =>
    set({ coffeemachinehandleColor: value }),
  setSelectedcoffeemachine: (value) => set({ selectedcoffeemachine: value }),

  //TRX Band :
  selectedTrxVariant: "door-anchor",
  setSelectedTrxVariant: (value) => set({ selectedTrxVariant: value }),

  //Vacuum one:
  selectedvacuum1_color: "black",
  setSelectedvacuum1_color: (value) => set({ selectedvacuum1_color: value }),

  selectedtoddlerSlide: "primary",
  setSelectedtoddlerSlide: (value) => set({ selectedtoddlerSlide: value }),

  selectedwaterSlide: "blue",
  setSelectedwaterSlide: (value) => set({ selectedwaterSlide: value }),

  //claudio toy
  selectedclaudio:"primary",
  setSelectedclaudio:(value)=> set({selectedclaudio:value}),


  //Cordless Vacuum:
  selectedcordlessvacuum_color: "blue",
  setSelectedcordlessvacuum_color: (value) =>
    set({ selectedcordlessvacuum_color: value }),

  
}));

export default useStore;
