import { Canvas, useThree } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  RandomizedLight,
  AccumulativeShadows,
} from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import { CofeeGrinder } from "../assets/CofeeGrinder";
import useStore from "../store";
import { gsap } from "gsap";
import { useEffect, useRef } from "react";
import { GreenCheckerbag } from "../assets/GreenCheckerbag";

function CameraAnimation({ selectedcoffeemachine, coffeemachinehandleColor }) {
  const { camera } = useThree();
  const prevSelected = useRef(selectedcoffeemachine);
  const prevHandleColor = useRef(coffeemachinehandleColor);

  useEffect(() => {
    if (prevSelected.current !== selectedcoffeemachine) {
      if (selectedcoffeemachine === "large") {
        gsap.to(camera.position, {
          duration: 1,
          x: -8,
          y: 3,
          z: 3,
          ease: "power2.inOut",
        });
        gsap.to(camera.rotation, {
          duration: 1,
          y: Math.PI * 2,
          ease: "power2.inOut",
        });
      } else if (selectedcoffeemachine === "medium") {
        gsap.to(camera.position, {
          duration: 1,
          x: -7,
          y: 2.7,
          z: 2,
          ease: "power2.inOut",
        });
        gsap.to(camera.rotation, {
          duration: 1,
          y: Math.PI * 2,
          ease: "power2.inOut",
        });
      } else {
        gsap.to(camera.position, {
          duration: 1,
          x: -6,
          y: 2.5,
          z: 1,
          ease: "power2.inOut",
        });
        gsap.to(camera.rotation, {
          duration: 1,
          y: 0,
          ease: "power2.inOut",
        });
      }
      prevSelected.current = selectedcoffeemachine;
    }

    if (prevHandleColor.current !== coffeemachinehandleColor) {
      gsap.to(camera.position, {
        duration: 1,
        x: -5,
        y: 2,
        z: 1.5,
        ease: "power2.inOut",
      });
      gsap.to(camera.rotation, {
        duration: 1,
        y: Math.PI / 4,
        ease: "power2.inOut",
      });
      prevHandleColor.current = coffeemachinehandleColor;
    }
  }, [selectedcoffeemachine, coffeemachinehandleColor, camera]);

  return null;
}

export default function CheckerBag() {
  const {
    setCoffeemachinecolor,
    setCoffeemachinehandleColor,
    setSelectedcoffeemachine,
    selectedcoffeemachine,
    coffeemachinehandleColor,
    coffeemachinecolor,
  } = useStore();
  return (
    <div className="flex justify-between w-[100%] h-[100vh]">
      <div className="w-[70%]">
        <Canvas shadows camera={{ position: [-6, 6.5, 3], fov: 45 }}>
          <color attach="background" args={["#f0f0f0"]} />
          <ambientLight intensity={0.5 * Math.PI} />
          <spotLight
            decay={0}
            position={[5, 5, -10]}
            angle={0.15}
            penumbra={1}
          />
          <pointLight decay={0} position={[-10, -10, -10]} />

          <GreenCheckerbag />

          <CameraAnimation
            selectedcoffeemachine={selectedcoffeemachine}
            coffeemachinehandleColor={coffeemachinehandleColor}
          />

          {/* <Text
            position={[0, 1.5, -1.8]}
            fontSize={0.8}
            color="black"
            anchorX="center"
            anchorY="middle"
          >
            Budan Expresso: $489
          </Text> */}

          <AccumulativeShadows
            temporal
            frames={100}
            color="orange"
            colorBlend={2}
            toneMapped={true}
            alphaTest={0.7}
            opacity={1}
            scale={12}
            position={[0, -0.5, 0]}
          >
            <RandomizedLight
              amount={8}
              radius={10}
              ambient={0.5}
              position={[5, 5, -10]}
              bias={0.001}
            />
          </AccumulativeShadows>
          <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />
          <OrbitControls
            makeDefault
            autoRotate
            autoRotateSpeed={0.1}
            minPolarAngle={0}
            maxPolarAngle={Math.PI / 2}
          />
          <EffectComposer>
            <Bloom luminanceThreshold={1} intensity={2} levels={9} mipmapBlur />
          </EffectComposer>
        </Canvas>
      </div>
      <div className="w-[30%] flex flex-col gap-2 px-4 py-4">
        <p className="font-bold text-2xl ">3D Configurator</p>
        <div className="h-[1px] bg-black/20 w-full mb-4"></div>

        <div className="flex justify-between mb-4">
          <div className="flex flex-col gap-0">
            <p className="text-lg font-bold">Petit Moyen </p>
            <p>Grass Woven Checker</p>
          </div>
          <div className="font-bold">428$</div>
        </div>

        <div className="font-bold">Choose Bag Color</div>

        <div className="grid grid-cols-4 mb-4">
          <div className="flex flex-col gap-1 items-center">
            <div
              className={`bg-[#362624] rounded-full h-12 w-12 border-2  ${
                coffeemachinecolor === "#362624"
                  ? "border-orange-400"
                  : "border-transparent"
              } cursor-pointer`}
              onClick={() => setCoffeemachinecolor("#362624")}
            ></div>
            <p>Brown</p>
          </div>

          <div className="flex flex-col gap-1 items-center">
            <div
              className={`bg-[#702963] rounded-full h-12 w-12 border-2  ${
                coffeemachinecolor === "#702963"
                  ? "border-orange-400"
                  : "border-transparent"
              } cursor-pointer`}
              onClick={() => setCoffeemachinecolor("#702963")}
            ></div>
            <p>Purple</p>
          </div>

          <div className="flex flex-col gap-1 items-center">
            <div
              className={`bg-[#000000] rounded-full h-12 w-12 border-2  ${
                coffeemachinecolor === "#000000"
                  ? "border-orange-400"
                  : "border-transparent"
              } cursor-pointer`}
              onClick={() => setCoffeemachinecolor("#000000")}
            ></div>
            <p>Black</p>
          </div>
          <div className="flex flex-col gap-1 items-center">
            <div
              className={`bg-[#D2042D] rounded-full h-12 w-12 border-2  ${
                coffeemachinecolor === "#D2042D"
                  ? "border-orange-400"
                  : "border-transparent"
              } cursor-pointer`}
              onClick={() => setCoffeemachinecolor("#D2042D")}
            ></div>
            <p>Cherry Red</p>
          </div>
        </div>

        <div className="font-bold">Choose Strap Color</div>

        <div className="grid grid-cols-4 mb-4">
          <div className="flex flex-col gap-1 items-center">
            <div
              className={`bg-[#F7F9F9] rounded-full h-12 w-12 border-2  ${
                coffeemachinehandleColor === "#F7F9F9"
                  ? "border-orange-400"
                  : "border-transparent"
              } cursor-pointer`}
              onClick={() => setCoffeemachinehandleColor("#F7F9F9")}
            ></div>
            <p>Silver</p>
          </div>

          <div className="flex flex-col gap-1 items-center">
            <div
              className={`bg-[#17202A] rounded-full h-12 w-12 border-2  ${
                coffeemachinehandleColor === "#17202A"
                  ? "border-orange-400"
                  : "border-transparent"
              } cursor-pointer`}
              onClick={() => setCoffeemachinehandleColor("#17202A")}
            ></div>
            <p>Black</p>
          </div>
        </div>

        <div className="font-bold">Choose Metal Variant</div>

        <div className="grid grid-cols-4 mb-4">
          <div className="flex flex-col gap-1 items-center">
            <div
              className={`bg-[#F7F9F9] rounded-full h-12 w-12 border-2  ${
                coffeemachinehandleColor === "#F7F9F9"
                  ? "border-orange-400"
                  : "border-transparent"
              } cursor-pointer`}
              onClick={() => setCoffeemachinehandleColor("#F7F9F9")}
            ></div>
            <p>Silver</p>
          </div>

          <div className="flex flex-col gap-1 items-center">
            <div
              className={`bg-[#17202A] rounded-full h-12 w-12 border-2  ${
                coffeemachinehandleColor === "#17202A"
                  ? "border-orange-400"
                  : "border-transparent"
              } cursor-pointer`}
              onClick={() => setCoffeemachinehandleColor("#17202A")}
            ></div>
            <p>Gold</p>
          </div>
        </div>
      </div>
    </div>
  );
}
