import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import useStore from "../store";

export function CrestLockBag(props) {
  const { crestlockbagColor } = useStore();
  const { nodes, materials } = useGLTF(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/configurator/crest-lock-bag.glb"
  );
  return (
    <group {...props} scale={[5, 5, 5]} position={[0, -0.5, 0]} dispose={null}>
      {crestlockbagColor === "#D2042D" && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.red.geometry}
          material={materials.red}
        />
      )}

      {crestlockbagColor === "#be6036" && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.brown.geometry}
          material={materials.brown}
        />
      )}

      {crestlockbagColor === "#433c3d" && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.navy.geometry}
          material={materials.navy}
        />
      )}

      {crestlockbagColor === "#000000" && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.black.geometry}
          material={materials.black}
        />
      )}
    </group>
  );
}
