/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 green-checker-bag.glb 
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export function GreenCheckerbag(props) {
  const { nodes, materials } = useGLTF("/green-checker-bag.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes["checker-bag"].geometry}
        scale={[8, 8, 8]}
        material={materials.green}
      />
    </group>
  );
}
