import React, { useEffect, useState } from "react";
import { Leva, useControls } from "leva";
import useStore from "../store";

const SelectVariant = ({ visible }) => {
  const meshGroups = useStore((state) => state.meshGroups);
  const selectedVariant = useStore((state) => state.selectedVariant);
  const setSelectedVariant = useStore((state) => state.setSelectedVariant);
  const setVisibleGroup = useStore((state) => state.setVisibleGroup);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (Object.keys(meshGroups).length > 0) {
      const firstGroup = Object.keys(meshGroups)[0];
      setSelectedVariant(firstGroup);
      setVisibleGroup(firstGroup);
      setOptions(Object.keys(meshGroups));
    }
  }, [meshGroups, setSelectedVariant, setVisibleGroup]);

  const controls = useControls(
    () => ({
      Variant: {
        options: options,
        value: options[0] || selectedVariant,
        onChange: (value) => {
          setSelectedVariant(value);
          setVisibleGroup(value);
        },
      },
    }),
    [options]
  );

  if (!visible) return null;

  return (
    <div>
      <Leva />
    </div>
  );
};

export default SelectVariant;
