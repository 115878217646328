import React, { useEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useParams } from "react-router-dom";
import useStore from "../store";

function Model() {
  const meshRef = useRef();
  const { type, model } = useParams();
  const { camera } = useThree();
  const setMeshGroups = useStore((state) => state.setMeshGroups);
  const visibleGroup = useStore((state) => state.visibleGroup);
  const setVisibleGroup = useStore((state) => state.setVisibleGroup);

  const { setGroundHeight } = useStore();

  console.log(model);
  console.log(type);

  const { scene } = useGLTF(
    `https://advertflair-public.s3.us-west-2.amazonaws.com/assets/configurator/${model}.glb`
  );

  useEffect(() => {
    let height = 0;
    if (type === "chair") {
      setGroundHeight(-0.45);
    } else if (type === "shoe") {
      setGroundHeight(-0.045);
    }

    if (scene) {
      const meshGroups = {};
      scene.traverse((child) => {
        if (child.isMesh) {
          const groupName = child.name.split("_")[0];
          if (!meshGroups[groupName]) {
            meshGroups[groupName] = [];
          }
          meshGroups[groupName].push(child.name);

          child.castShadow = true;
          child.receiveShadow = true;
        }
      });

      setMeshGroups(meshGroups);
      const firstGroup = Object.keys(meshGroups)[0];
      if (firstGroup) {
        setVisibleGroup(firstGroup);
      }

      const box = new THREE.Box3().setFromObject(scene);
      const center = new THREE.Vector3();
      box.getCenter(center);
      scene.position.sub(center);

      const size = new THREE.Vector3();
      box.getSize(size);
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = 75;
      const cameraZ = maxDim / (2 * Math.atan((Math.PI * fov) / 360));

      camera.position.set(0, 0, cameraZ * 1.5);
      camera.lookAt(0, 0, 0);
    }
  }, [scene, camera, setMeshGroups, setVisibleGroup]);

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.traverse((child) => {
        if (child.isMesh) {
          const groupName = child.name.split("_")[0];
          child.visible = groupName === visibleGroup;
        }
      });
    }
  });

  return <primitive ref={meshRef} object={scene} />;
}

export default Model;
