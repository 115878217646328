import { Canvas, useThree } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  RandomizedLight,
  AccumulativeShadows,
} from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import useStore from "../store";
import { useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useGLTF } from "@react-three/drei";
import gsap from "gsap";

export function Asset(props) {
  const { nodes, materials } = useGLTF(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/output/Stone_slim_locket.glb"
  );
  const { selectedvacuum1_color } = useStore();
  return (
    <group {...props} position={[0, -0.02, 0]} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.chain.geometry}
        material={materials.chain}
      />
    </group>
  );
}

function CameraAnimation() {
  const { camera } = useThree();

  const defaultPosition = { x: 0, y: 0, z: 0.15 };
  const defaultRotation = { x: 0, y: 0, z: 0 };

  useEffect(() => {
    camera.position.set(
      defaultPosition.x,
      defaultPosition.y,
      defaultPosition.z
    );
    camera.rotation.set(
      defaultRotation.x,
      defaultRotation.y,
      defaultRotation.z
    );
  }, [camera]);

  return null;
}

export default function StoneSlimLocket() {
  const { selectedvacuum1_color, setSelectedvacuum1_color } = useStore();
  const [mobileModal, setMobileModal] = useState(true);
  return (
    <div className="w-[100%] h-[100vh] flex">
      <Canvas shadows camera={{ position: [-6, 3.5, 1], fov: 45 }}>
        <color attach="background" args={["#f0f0f0"]} />
        <ambientLight intensity={0.1 * Math.PI} />
        <spotLight decay={0} position={[5, 5, -10]} angle={0.15} penumbra={1} />
        <pointLight decay={0} position={[-10, -10, -10]} />

        <Asset />
        <CameraAnimation />

        <AccumulativeShadows
          temporal
          frames={100}
          color="orange"
          colorBlend={2}
          toneMapped={true}
          alphaTest={0.7}
          opacity={1}
          scale={12}
          position={[0, -0.1, 0]}
        >
          <RandomizedLight
            amount={8}
            radius={10}
            ambient={0.5}
            position={[5, 5, -10]}
            bias={0.001}
          />
        </AccumulativeShadows>
        <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />
        <OrbitControls
          makeDefault
          autoRotate
          autoRotateSpeed={0.1}
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 2}
        />
        <EffectComposer>
          <Bloom luminanceThreshold={1} intensity={0.5} levels={9} mipmapBlur />
        </EffectComposer>
      </Canvas>

      {/* Mobile Screen */}
      <div
        className="flex md:hidden absolute top-2 right-2 w-8 h-8  items-center justify-center rounded-full bg-orange-400 z-30 hover:bg-orange-500 cursor-pointer"
        onClick={() => setMobileModal(!mobileModal)}
      >
        <GiHamburgerMenu className="w-full justify-end text-white font-bold" />
      </div>
    </div>
  );
}
