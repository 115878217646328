import { Canvas, useThree } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  RandomizedLight,
  AccumulativeShadows,
} from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import useStore from "../store";
import { useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useGLTF } from "@react-three/drei";
import gsap from "gsap";

export function Asset(props) {
  const { nodes, materials } = useGLTF(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/configurator/Water_Slide.glb"
  );
  const { selectedwaterSlide } = useStore();
  return (
    <group {...props} position={[0, -0.5, 0]} dispose={null}>
      {selectedwaterSlide === "blue" && (
        <>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Blue_1.geometry}
            material={materials.Blue_1_Color}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Blue_2.geometry}
            material={materials["Blue_@_Color"]}
          />
        </>
      )}

      {selectedwaterSlide === "green" && (
        <>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Green_1.geometry}
            material={materials.Green_1_Color}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Green_2.geometry}
            material={materials.Green_2_Color}
          />
        </>
      )}

      {selectedwaterSlide === "orange" && (
        <>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Orange_1.geometry}
            material={materials.Orange_1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Orange_2.geometry}
            material={materials.Orange_2}
          />
        </>
      )}
    </group>
  );
}

function CameraAnimation() {
  const { camera } = useThree();

  const defaultPosition = { x: 0, y: 0, z: 2 };
  const defaultRotation = { x: 0, y: 0, z: 0 };

  useEffect(() => {
    camera.position.set(
      defaultPosition.x,
      defaultPosition.y,
      defaultPosition.z
    );
    camera.rotation.set(
      defaultRotation.x,
      defaultRotation.y,
      defaultRotation.z
    );
  }, [camera]);

  return null;
}

export default function WaterSlide() {
  const { selectedwaterSlide, setSelectedwaterSlide } = useStore();
  const [mobileModal, setMobileModal] = useState(true);
  return (
    <div className="w-[100%] h-[100vh] flex">
      <Canvas shadows camera={{ position: [-6, 2.5, 3], fov: 45 }}>
        <color attach="background" args={["#f0f0f0"]} />
        <ambientLight intensity={0.5 * Math.PI} />
        <spotLight decay={0} position={[5, 5, -10]} angle={0.15} penumbra={1} />
        <pointLight decay={0} position={[-10, -10, -10]} />

        <Asset />
        <CameraAnimation />

        <AccumulativeShadows
          temporal
          frames={100}
          color="orange"
          colorBlend={2}
          toneMapped={true}
          alphaTest={0.7}
          opacity={1}
          scale={12}
          position={[0, -0.5, 0]}
        >
          <RandomizedLight
            amount={8}
            radius={10}
            ambient={0.5}
            position={[5, 5, -10]}
            bias={0.001}
          />
        </AccumulativeShadows>
        <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />
        <OrbitControls
          makeDefault
          autoRotate
          autoRotateSpeed={0.1}
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 2}
        />
        <EffectComposer>
          <Bloom luminanceThreshold={1} intensity={0.7} levels={9} mipmapBlur />
        </EffectComposer>
      </Canvas>

      {/* Mobile Screen */}
      <div
        className="flex md:hidden absolute top-2 right-2 w-8 h-8  items-center justify-center rounded-full bg-orange-400 z-30 hover:bg-orange-500 cursor-pointer"
        onClick={() => setMobileModal(!mobileModal)}
      >
        <GiHamburgerMenu className="w-full justify-end text-white font-bold" />
      </div>

      {mobileModal && (
        <div className="flex-col md:hidden absolute h-[30vh] w-full bottom-0 right-0 bg-white/80 backdrop-blur-md border border-1 border-white shadow-lg  p-4 overflow-auto">
          <p className="font-bold text-xl">3D Configurator</p>
          <div className="h-[1px] bg-black/20 w-full my-2"></div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-end">
              <div className="flex flex-col gap-[1px]">
                <p className="text-lg font-bold">Playful</p>
                <p className="text-xs font-bold">Water Slide</p>
              </div>
              <div className="font-bold"> $850</div>
            </div>

            <div className="flex flex-col gap-2">
              <p className="font-bold">Choose Variant</p>
              <div className="flex flex-col gap-3">
                <div
                  className={`px-4 py-2 rounded-sm  hover:bg-gray-200 cursor-pointer ${
                    selectedwaterSlide === "blue"
                      ? "bg-gray-200 font-bold"
                      : "bg-white"
                  }`}
                  onClick={() => setSelectedwaterSlide("blue")}
                >
                  Blue
                </div>

                <div
                  className={`px-4 py-2 rounded-sm  hover:bg-gray-200 cursor-pointer ${
                    selectedwaterSlide === "green"
                      ? "bg-gray-200 font-bold"
                      : "bg-white"
                  }`}
                  onClick={() => setSelectedwaterSlide("green")}
                >
                  Green
                </div>

                <div
                  className={`px-4 py-2 rounded-sm  hover:bg-gray-200 cursor-pointer ${
                    selectedwaterSlide === "orange"
                      ? "bg-gray-200 font-bold"
                      : "bg-white"
                  }`}
                  onClick={() => setSelectedwaterSlide("orange")}
                >
                  Orange
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hidden md:flex md:flex-col bg-white/10 backdrop-blur-md border border-1 border-white shadow-lg h-[100vh] w-[40vh] p-3">
        <p className="font-bold text-2xl">3D Configurator</p>
        <div className="h-[1px] bg-black/20 w-full my-2"></div>

        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-end">
            <div className="flex flex-col gap-[1px]">
              <p className="text-lg font-bold">Playful</p>
              <p className="text-xs font-bold">Water Slide</p>
            </div>
            <div className="font-bold"> $850</div>
          </div>

          <div className="flex flex-col gap-2">
            <p className="font-bold">Choose Variant</p>
            <div className="flex flex-col gap-3">
              <div
                className={`px-4 py-2 rounded-sm  hover:bg-gray-200 cursor-pointer ${
                  selectedwaterSlide === "blue"
                    ? "bg-gray-200 font-bold"
                    : "bg-white"
                }`}
                onClick={() => setSelectedwaterSlide("blue")}
              >
                Blue
              </div>

              <div
                className={`px-4 py-2 rounded-sm  hover:bg-gray-200 cursor-pointer ${
                  selectedwaterSlide === "green"
                    ? "bg-gray-200 font-bold"
                    : "bg-white"
                }`}
                onClick={() => setSelectedwaterSlide("green")}
              >
                Green
              </div>

              <div
                className={`px-4 py-2 rounded-sm  hover:bg-gray-200 cursor-pointer ${
                  selectedwaterSlide === "orange"
                    ? "bg-gray-200 font-bold"
                    : "bg-white"
                }`}
                onClick={() => setSelectedwaterSlide("orange")}
              >
                Orange
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
