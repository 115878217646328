import { Canvas, useThree } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  RandomizedLight,
  AccumulativeShadows,
  Text,
} from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import useStore from "../store";
import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { ElenWedgeSandal } from "../assets/ElenWedgeSandal";

function CameraAnimation({ selectedcoffeemachine, coffeemachinehandleColor }) {
  const { camera } = useThree();
  const prevSelected = useRef(selectedcoffeemachine);
  const prevHandleColor = useRef(coffeemachinehandleColor);

  useEffect(() => {
    if (prevSelected.current !== selectedcoffeemachine) {
      if (selectedcoffeemachine === "large") {
        gsap.to(camera.position, {
          duration: 1,
          x: -8,
          y: 3,
          z: 3,
          ease: "power2.inOut",
        });
        gsap.to(camera.rotation, {
          duration: 1,
          y: Math.PI * 2,
          ease: "power2.inOut",
        });
      } else if (selectedcoffeemachine === "medium") {
        gsap.to(camera.position, {
          duration: 1,
          x: -7,
          y: 2.7,
          z: 2,
          ease: "power2.inOut",
        });
        gsap.to(camera.rotation, {
          duration: 1,
          y: Math.PI * 2,
          ease: "power2.inOut",
        });
      } else {
        gsap.to(camera.position, {
          duration: 1,
          x: -6,
          y: 2.5,
          z: 1,
          ease: "power2.inOut",
        });
        gsap.to(camera.rotation, {
          duration: 1,
          y: 0,
          ease: "power2.inOut",
        });
      }
      prevSelected.current = selectedcoffeemachine;
    }

    if (prevHandleColor.current !== coffeemachinehandleColor) {
      gsap.to(camera.position, {
        duration: 1,
        x: -5,
        y: 2,
        z: 1.5,
        ease: "power2.inOut",
      });
      gsap.to(camera.rotation, {
        duration: 1,
        y: Math.PI / 4,
        ease: "power2.inOut",
      });
      prevHandleColor.current = coffeemachinehandleColor;
    }
  }, [selectedcoffeemachine, coffeemachinehandleColor, camera]);

  return null;
}

export default function ElenWedgesandal() {
  const {
    selectedcoffeemachine,
    coffeemachinehandleColor,
    elenwedgeSandalcolor,
    setElenwedgeSandalcolor,
  } = useStore();

  const [mobileModal, setMobileModal] = useState(true);
  return (
    <div className="w-[100%] h-[100vh]">
      <Canvas shadows camera={{ position: [-6, 2.5, 3], fov: 45 }}>
        <color attach="background" args={["#f0f0f0"]} />
        <ambientLight intensity={0.5 * Math.PI} />
        <spotLight decay={0} position={[5, 5, -10]} angle={0.15} penumbra={1} />
        <pointLight decay={0} position={[-10, -10, -10]} />

        <ElenWedgeSandal />
        <CameraAnimation
          selectedcoffeemachine={selectedcoffeemachine}
          coffeemachinehandleColor={coffeemachinehandleColor}
        />

        <Text
          position={[0, 0.5, -1.8]}
          fontSize={0.4}
          color="black"
          anchorX="center"
          anchorY="middle"
        >
          Ellen Sandal : $498
        </Text>

        <AccumulativeShadows
          temporal
          frames={100}
          color="orange"
          colorBlend={2}
          toneMapped={true}
          alphaTest={0.7}
          opacity={1}
          scale={12}
          position={[0, -0.5, 0]}
        >
          <RandomizedLight
            amount={8}
            radius={10}
            ambient={0.5}
            position={[5, 5, -10]}
            bias={0.001}
          />
        </AccumulativeShadows>
        <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />
        <OrbitControls
          makeDefault
          autoRotate
          autoRotateSpeed={0.1}
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 2}
        />
        <EffectComposer>
          <Bloom luminanceThreshold={1} intensity={0.7} levels={9} mipmapBlur />
        </EffectComposer>
      </Canvas>

      {/* Mobile Screen */}
      <div
        className="flex md:hidden absolute top-2 right-2 w-8 h-8  items-center justify-center rounded-full bg-orange-400 z-30 hover:bg-orange-500 cursor-pointer"
        onClick={() => setMobileModal(!mobileModal)}
      >
        <GiHamburgerMenu className="w-full justify-end text-white font-bold" />
      </div>

      {mobileModal && (
        <div className="flex-col md:hidden absolute h-[30vh] w-full bottom-0 right-0 bg-white/80 backdrop-blur-md border border-1 border-white shadow-lg  p-4 overflow-auto">
          <p className="font-bold text-xl">3D Configurator</p>
          <div className="h-[1px] bg-black/20 w-full my-2"></div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-end">
              <div className="flex flex-col gap-[1px]">
                <p className="text-lg font-bold">Ellen Wedge Sandal</p>
                <p className="text-xs font-bold">Veronica Beard</p>
              </div>
              <div className="font-bold">498$</div>
            </div>

            <div className="flex flex-col gap-2">
              <p className="font-bold">Choose Sandal Color</p>

              <div className="grid grid-cols-5">
                <div className="flex flex-col gap-1 items-center">
                  <div
                    className={`bg-[#be6036] rounded-full h-12 w-12 border-2  ${
                      elenwedgeSandalcolor === "#be6036"
                        ? "border-orange-400"
                        : "border-transparent"
                    } cursor-pointer`}
                    onClick={() => setElenwedgeSandalcolor("#be6036")}
                  ></div>
                  <p>Caramel</p>
                </div>

                <div className="flex flex-col gap-1 items-center">
                  <div
                    className={`bg-[#000000] rounded-full h-12 w-12 border-2  ${
                      elenwedgeSandalcolor === "#000000"
                        ? "border-orange-400"
                        : "border-transparent"
                    } cursor-pointer`}
                    onClick={() => setElenwedgeSandalcolor("#000000")}
                  ></div>
                  <p>Black</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hidden md:flex md:flex-col absolute top-0 right-0 bg-white/10 backdrop-blur-md border border-1 border-white shadow-lg h-[100vh] p-6">
        <p className="font-bold text-2xl">3D Configurator</p>
        <div className="h-[1px] bg-black/20 w-full my-2"></div>

        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-end">
            <div className="flex flex-col gap-[1px]">
              <p className="text-lg font-bold">Ellen Wedge Sandal</p>
              <p className="text-xs font-bold">Veronica Beard</p>
            </div>
            <div className="font-bold">498$</div>
          </div>

          <div className="flex flex-col gap-2">
            <p className="font-bold">Choose Sandal Color</p>

            <div className="grid grid-cols-5 gap-3">
              <div className="flex flex-col gap-1 items-center">
                <div
                  className={`bg-[#be6036] rounded-full h-12 w-12 border-2  ${
                    elenwedgeSandalcolor === "#be6036"
                      ? "border-orange-400"
                      : "border-transparent"
                  } cursor-pointer`}
                  onClick={() => setElenwedgeSandalcolor("#be6036")}
                ></div>
                <p>Caramel</p>
              </div>

              <div className="flex flex-col gap-1 items-center">
                <div
                  className={`bg-[#000000] rounded-full h-12 w-12 border-2  ${
                    elenwedgeSandalcolor === "#000000"
                      ? "border-orange-400"
                      : "border-transparent"
                  } cursor-pointer`}
                  onClick={() => setElenwedgeSandalcolor("#000000")}
                ></div>
                <p>Black</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
