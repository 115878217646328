import React, { useRef } from "react";
import useStore from "../store";

function Ground() {
  const { groundHeight } = useStore();

  const groundRef = useRef();
  return (
    <mesh
      ref={groundRef}
      receiveShadow
      rotation-x={-Math.PI / 2}
      position={[0, groundHeight, 0]}
    >
      <planeGeometry args={[100, 100]} />
      <shadowMaterial opacity={0.2} />
    </mesh>
  );
}

export default Ground;
