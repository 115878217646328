import React, { useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import * as THREE from "three";
import Model from "../components/Model";
import Ground from "../components/Ground";
import SelectVariant from "../components/SelectVariant";
import useStore from "../store";

function Scene() {
  return (
    <>
      <Model />
      <Ground />
      <OrbitControls />
      <Environment
        files={
          "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/lights/hayloft_4k.hdr"
        }
      />
      <directionalLight
        position={[7, 6, 7]}
        intensity={1}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-near={0.5}
        shadow-camera-far={20}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />
    </>
  );
}

function Configurator() {
  const meshGroups = useStore((state) => state.meshGroups);
  const setMeshGroups = useStore((state) => state.setMeshGroups);
  const setSelectedVariant = useStore((state) => state.setSelectedVariant);
  const setVisibleGroup = useStore((state) => state.setVisibleGroup);

  useEffect(() => {
    // Manually setting mesh groups
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const fetchedMeshGroups = {
      // Add more variants if needed
    };

    const capitalizedMeshGroups = Object.keys(fetchedMeshGroups).reduce(
      (acc, key) => {
        acc[capitalizeFirstLetter(key)] = fetchedMeshGroups[key];
        return acc;
      },
      {}
    );
    setMeshGroups(capitalizedMeshGroups);

    if (Object.keys(capitalizedMeshGroups).length > 0) {
      const firstGroup = Object.keys(capitalizedMeshGroups)[0];
      setSelectedVariant(firstGroup);
      setVisibleGroup(firstGroup);
    }
  }, [setMeshGroups, setSelectedVariant, setVisibleGroup]);

  return (
    <>
      <SelectVariant visible={Object.keys(meshGroups).length > 0} />
      <Canvas
        shadows
        camera={{ position: [0, 2, 5], fov: 75 }}
        onCreated={({ gl }) => {
          gl.setClearColor("white");
          gl.shadowMap.enabled = true;
          gl.shadowMap.type = THREE.PCFSoftShadowMap; // Changed to PCFSoftShadowMap for better quality
        }}
      >
        <Scene />
      </Canvas>
    </>
  );
}

export default Configurator;
