import React from "react";
import { useGLTF } from "@react-three/drei";
import useStore from "../store";

export function ElenWedgeSandal(props) {
  const { elenwedgeSandalcolor } = useStore();
  const { nodes, materials } = useGLTF(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/configurator/Ellen-Wedge-Sandal.glb"
  );
  return (
    <group {...props} scale={[5, 5, 5]} position={[0, -0.5, 0]} dispose={null}>
      {elenwedgeSandalcolor === "#be6036" && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.caramel.geometry}
          material={materials.caramel}
        />
      )}

      {elenwedgeSandalcolor === "#000000" && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.black.geometry}
          material={materials.black}
        />
      )}
    </group>
  );
}
