import React from "react";
import DiamondRing from "./pages/DiamondRing";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Configurator from "./pages/Configurator";
import Home from "./pages/Home";
import CoffeeMaker from "./pages/CoffeeMaker";
import CheckerBag from "./pages/CheckerBag";
import CrestLockbag from "./pages/CrestLockbag";
import ElenWedgesandal from "./pages/ElenWedgesandal";
import TrxPro4 from "./pages/TrxPro4";
import RobotVacuumOne from "./pages/RobotVacuumOne";
import CordlessVacuum from "./pages/CordlessVacuum";
import Dorothy_Medallion from "./pages/Dorothy_Medallion";
import StoneSlimLocket from "./pages/StoneSlimLocket";
import DiamondRing2 from "./pages/DiamondRing2";
import ToddlerSlide from "./pages/ToddlerSlide";
import WaterSlide from "./pages/WaterSlide";
import ClaudioToy from "./pages/ClaudioToy";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/jewellery/diamond-ring-01" element={<DiamondRing />} />
          <Route path="/product/:type/:model/" element={<Configurator />} />

          <Route path="/product/coffeemaker/" element={<CoffeeMaker />} />
          <Route path="/product/trx-pro-4/" element={<TrxPro4 />} />

          <Route
            path="/product/robot-vacuum-one/"
            element={<RobotVacuumOne />}
          />

          <Route path="/product/diamond-ring-2/" element={<DiamondRing2 />} />
          <Route path="/product/claudio/" element={<ClaudioToy />} />

          <Route
            path="/product/dorathy-medallion/"
            element={<Dorothy_Medallion />}
          />

          <Route
            path="/product/stone-slim-locket/"
            element={<StoneSlimLocket />}
          />

          <Route path="/product/toddler-slide/" element={<ToddlerSlide />} />

          <Route path="/product/water-slide/" element={<WaterSlide />} />

          <Route
            path="/product/cordless-vacuum/"
            element={<CordlessVacuum />}
          />

          <Route path="/product/checker-bag/" element={<CheckerBag />} />
          <Route path="/product/crest-lock-bag/" element={<CrestLockbag />} />
          <Route
            path="/product/elen-wedge-sandal/"
            element={<ElenWedgesandal />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
