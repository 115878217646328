import React from "react";
import { useGLTF } from "@react-three/drei";
import useStore from "../store";

export function CofeeGrinder(props) {
  const {
    coffeemachinecolor,
    coffeemachinehandleColor,
    selectedcoffeemachine,
  } = useStore();
  const { nodes, materials } = useGLTF(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/configurator/coffee_machine_01.glb"
  );
  return (
    <group {...props} dispose={null} scale={[5, 5, 5]} position={[0, -0.5, 0]}>
      <mesh
        geometry={nodes["ON-OFF_Button"].geometry}
        material={materials.BlackPlastic}
        castShadow
        receiveShadow
      />

      <mesh
        geometry={nodes.Legs.geometry}
        material={materials.BlackPlasticGlossy}
      />
      <mesh geometry={nodes.Grinder.geometry} material={materials.MainBody} />

      {selectedcoffeemachine === "default" && (
        <>
          <mesh
            geometry={nodes.Cover.geometry}
            material={materials.BlackPlastic}
          />
          <mesh
            geometry={nodes.CoffeeBeansbox.geometry}
            material={materials["transparent plastic"]}
          />
        </>
      )}

      {selectedcoffeemachine === "medium" && (
        <>
          <mesh
            geometry={nodes.CoffeeBeansbox_medium.geometry}
            material={materials["transparent plastic.001"]}
          />
          <mesh
            geometry={nodes.Cover_medium.geometry}
            material={materials["BlackPlastic.001"]}
            position={[-0.237, 0.55, 0]}
          />
        </>
      )}

      {selectedcoffeemachine === "large" && (
        <>
          <mesh
            geometry={nodes.CoffeeBeansbox_large.geometry}
            material={materials["transparent plastic.002"]}
          />

          <mesh
            geometry={nodes.Cover_large.geometry}
            material={materials["BlackPlastic.002"]}
          />
        </>
      )}

      <mesh
        geometry={nodes.Buttons.geometry}
        material={materials.BrushedMetal}
      />
      <mesh geometry={nodes.Beans.geometry} material={materials.Beans} />
      <mesh
        geometry={nodes.Cube002.geometry}
        material={materials.BrushedMetal}
      />
      <mesh
        geometry={nodes.Cube002_1.geometry}
        material={materials.MainBody}
        material-color={coffeemachinecolor}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Cube002_2.geometry}
        material={materials.Display}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Cube002_3.geometry}
        material={materials.MainBodyLegendButtons}
      />
      <mesh
        geometry={nodes.Cube002_4.geometry}
        material={materials.MainBodyLegendGrinder}
      />
      <mesh
        geometry={nodes.Cube007.geometry}
        material={materials.BrushedMetal}
      />
      <mesh
        geometry={nodes.Cube007_1.geometry}
        material={materials["Grip.001"]}
      />
      <mesh
        geometry={nodes.Circle144.geometry}
        material={materials.BlackPlastic}
      />
      <mesh geometry={nodes.Circle144_1.geometry} material={materials.Chrome} />
      <mesh geometry={nodes.Cylinder_1.geometry} material={materials.Grip} />
      <mesh
        geometry={nodes.Cylinder_2.geometry}
        material={materials.BrushedMetal}
      />
      <mesh
        geometry={nodes.Cylinder_3.geometry}
        material={materials.BlackPlastic}
      />
      <mesh geometry={nodes.Sphere.geometry} material={materials.Chrome} />
      <mesh
        geometry={nodes.Sphere_1.geometry}
        material={materials.BlackPlasticGlossy}
        material-color={coffeemachinehandleColor}
      />
    </group>
  );
}
